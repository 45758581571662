import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { mergeMap } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetQuestionnaireQuestionsUseCase } from 'src/app/core/usecases/get-questionnaire-questions.usecase';
import { PlainLayoutService } from '../shared/services/plain-layout.service';
import { AddedToWaitingListComponent } from './components/added-to-waiting-list/added-to-waiting-list.component';
import { ApprovedComponent } from './components/approved/approved.component';
import { Step, StepperComponent } from './components/stepper/stepper.component';
import { QuestionnaireEvaluationVerdict } from './shared/interfaces';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _cio: any;

const QUESTIONNAIRE_EVALUATION_VERDICT_COMPONENTS: {
  [K in QuestionnaireEvaluationVerdict]: any;
} = {
  approved: ApprovedComponent,
  addedToWaitingList: AddedToWaitingListComponent,
};

@Component({
  templateUrl: 'questionnaire.component.html',
  styleUrls: ['questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, StepperComponent],
})
export class QuestionnaireComponent implements OnInit {
  @ViewChild('evaluationVerdictTemplate', { read: ViewContainerRef, static: false })
  private _evaluationVerdictTemplate: ViewContainerRef;

  public userIsReadyToStart = false;

  public userHasBeenEvaluated = false;

  public steps: Array<Step>;

  public questionnaireName: string;

  constructor(
    private getQuestionnaireQuestionsUseCase: GetQuestionnaireQuestionsUseCase,
    private _changeDetectorRef: ChangeDetectorRef,
    private plainLayoutService: PlainLayoutService,
    private _route: ActivatedRoute,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {
    // TODO Make this optional by design.
    //  For now we don't need the close button.
    this.plainLayoutService.setCloseButton(false);
  }

  ngOnInit(): void {
    this._route.params
      .pipe(
        mergeMap((params) => {
          this.questionnaireName = params.name;
          const eventName = `${this.questionnaireName}-questionnaire_page_load`;
          this._logMixpanelEventUseCase.execute({ eventName });
          if (typeof _cio !== 'undefined') {
            _cio.track(eventName);
          }
          return this.getQuestionnaireQuestionsUseCase.execute(params.name);
        }),
      )
      .subscribe({
        next: (data) => {
          this.steps = data.questions.map((question, index) => ({
            index: index + 1,
            meta: {
              label: index + 1,
              content: question,
            },
          }));
        },
      });
  }

  public confirmStartFillingQuestionnaire(): void {
    this.userIsReadyToStart = true;
    const eventName = `${this.questionnaireName}-questionnaire_start_click`;
    this._logMixpanelEventUseCase.execute({ eventName });
    if (typeof _cio !== 'undefined') {
      _cio.track(eventName);
    }
  }

  public resolveQuestionnaireEvaluationVerdict(verdict: QuestionnaireEvaluationVerdict): void {
    this.userHasBeenEvaluated = true;
    this._commonChangeDetectorDetectChanges();
    const componentToInjectInstance = this._evaluationVerdictTemplate.createComponent(
      QUESTIONNAIRE_EVALUATION_VERDICT_COMPONENTS[verdict],
    );
    componentToInjectInstance.changeDetectorRef.detectChanges();
  }

  private _commonChangeDetectorDetectChanges(): void {
    this._changeDetectorRef.detectChanges();
  }
}
