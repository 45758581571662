import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { provideRouter } from '@angular/router';
import { AddedToWaitingListComponent } from './components/added-to-waiting-list/added-to-waiting-list.component';
import { ApprovedComponent } from './components/approved/approved.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { QuestionnaireComponent } from './questionnaire.component';
import { QuestionBoxComponent } from './shared/question-box/question-box.component';
import { StepLabelComponent } from './shared/step-label/step-label.component';

const SUB_COMPONENTS = [StepperComponent, AddedToWaitingListComponent, ApprovedComponent];

const DYNAMICALLY_INJECTED_COMPONENTS = [StepLabelComponent, QuestionBoxComponent];

@NgModule({
  exports: [],
  imports: [
    CommonModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    QuestionnaireComponent,
    ...SUB_COMPONENTS,
    ...DYNAMICALLY_INJECTED_COMPONENTS,
  ],
  providers: [
    provideRouter([
      {
        path: '',
        pathMatch: 'full',
        component: QuestionnaireComponent,
      },
      {
        path: '**',
        redirectTo: '',
      },
    ]),
  ],
})
export class QuestionnaireModule {}
