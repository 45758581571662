import { Component, inject, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';

@Component({
  styleUrls: ['added-to-waiting-list.component.scss'],
  templateUrl: 'added-to-waiting-list.component.html',
  standalone: true,
})
export class AddedToWaitingListComponent {
  public questionnaireAssets = 'assets/img/questionnaire';

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    this.trackWaitingList();
  }

  backToWebsite(): void {
    this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
  }

  public trackWaitingList(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'ksa_questionnaire_waitlisted' });
  }
}
