<div class="added-to-waiting-list">
  <div class="added-to-waiting-list__section-one">
    <div class="added-to-waiting-list__section-one__icon">
      <img
        loading="lazy"
        src="{{ questionnaireAssets + '/added-to-waiting-list-thanks-icon.svg' }}"
        alt="added-to-waiting-list-thanks-icon"
      />
    </div>
    <span class="added-to-waiting-list__section-one__instructions">
      شكراً لك، لقد قمنا بإضافتك الى قائمة الانتظار و سنقوم بالتواصل معك في اقرب وقت ممكن
    </span>
  </div>
  <div class="added-to-waiting-list__section-two">
    <button
      class="added-to-waiting-list__section-two__go-back-to-main-screen"
      (click)="backToWebsite()"
    >
      <span class="added-to-waiting-list__section-two__go-back-to-main-screen__text"
        >العودة الى موقع تاجر</span
      >
    </button>
  </div>
</div>
