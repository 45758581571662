import { NgFor } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { LogoutUseCase } from 'src/app/core/usecases/auth/logout.usecase';
import { PlainLayoutService } from 'src/app/presentation/shared/services/plain-layout.service';

@Component({
  selector: 'app-approved',
  styleUrls: ['approved.component.scss'],
  templateUrl: 'approved.component.html',
  standalone: true,
  imports: [NgFor],
})
export class ApprovedComponent implements OnInit {
  public changesSection: { img: string; text: string }[];

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private plainLayoutService: PlainLayoutService,
    private _logoutUseCase: LogoutUseCase,
  ) {
    this.trackApprovedQuestionnaire();
  }

  ngOnInit(): void {
    this.changesSection = [
      {
        img: 'change-country.jpeg',
        text: 'يمكنك تغيير الدولة من شريط التنقل',
      },
      {
        img: 'profits.jpeg',
        text: 'يمكنك مشاهدة ارباحك  في كل دولة على حدى',
      },
      {
        img: 'catalogue-cart.jpeg',
        text: 'ستحصل على عربة و كتالوج للمنتجات منفصلين لكل دولة',
      },
    ];
  }

  onBackToTaagerClick() {
    this.plainLayoutService.setCloseButton(false);
    this._logoutUseCase.execute();
  }

  public trackApprovedQuestionnaire() {
    this._logMixpanelEventUseCase.execute({ eventName: 'ksa_questionnaire_passed' });
  }
}
