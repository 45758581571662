import {
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, take } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { QuestionnaireSubmissionModel } from 'src/app/core/domain/questionnaire.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { SubmitQuestionnaireAnswersUseCase } from 'src/app/core/usecases/submit-questionnaire-answers.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { resolveUserLandingUtility } from 'src/app/presentation/shared/utilities/resolve-user-landing.utility';
import { QuestionnaireEvaluationVerdict } from '../../shared/interfaces';
import { QuestionBoxComponent } from '../../shared/question-box/question-box.component';
import { StepLabelComponent } from '../../shared/step-label/step-label.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _cio: any;

export interface Step {
  index: number;
  meta: {
    label: any;
    content: any;
  };
}

interface QuestionAndAnswers {
  questionId: string;
  answerIds: Array<string>;
  comment: string;
}

@Component({
  selector: 'app-stepper-component',
  styleUrls: ['stepper.component.scss'],
  templateUrl: 'stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatProgressBarModule,
    NgTemplateOutlet,
    NgIf,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgFor,
    NgSwitchDefault,
  ],
})
export class StepperComponent implements OnInit, OnDestroy {
  @Input() steps: any;

  @Input() questionnaireName: string;

  @Input()
  public retrySubmittingQuestionnaire$: Subject<boolean> = new Subject<boolean>();

  @Output()
  public questionnaireEvaluationVerdict$: EventEmitter<QuestionnaireEvaluationVerdict> =
    new EventEmitter();

  @ViewChild('stepLabelTemplateOutletTemplate', { read: ViewContainerRef })
  private _stepLabelTemplateOutletTemplate: ViewContainerRef;

  @ViewChild('stepQuestionBoxOutletTemplate', { read: ViewContainerRef })
  private _stepQuestionBoxOutletTemplate: ViewContainerRef;

  public currentPage = 1;

  public currentProgress = 0;

  public showSubmitStep = false;

  public currentActiveStep: Step;

  public isSubmittingQuestionnaire = false;

  public submittingQuestionnaireFailed = false;

  public questionnaireAssetsFolder = 'assets/img/questionnaire';

  private singleSelectAnswersIds: any[] = [];

  public questionnaireForm: UntypedFormArray = new UntypedFormArray([]);

  private _onDestroy$: Subject<boolean> = new Subject<boolean>();

  private _stepLabelComponentRef: ComponentRef<StepLabelComponent>;

  private _questionBoxComponentRef: ComponentRef<QuestionBoxComponent>;

  constructor(
    private submitQuestionnaireAnswersUseCase: SubmitQuestionnaireAnswersUseCase,
    private toaster: ToastrService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    /**
     * These two methods will be moved into the resolution of the server side response
     */
    this._initializeQuestionnaireForm(this.steps);
    this._doCalculateProgress();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  public moveToPreviousStep(): void {
    if (this.currentPage === 1) {
      return;
    }
    if (this.showSubmitStep) {
      this.showSubmitStep = false;
    } else {
      this.currentPage -= 1;
      this._doCalculateProgress();
    }
  }

  public moveToNextStep(): void {
    if (this.currentPage === this.steps.length) {
      this.showSubmitStep = true;
      return;
    }
    const eventName = `${this.questionnaireName}-questionnaire_next_click`;
    const payload = { question_id: this.steps[this.currentPage - 1].meta.content.id };
    this._logMixpanelEventUseCase.execute({
      eventName,
      payload,
    });
    if (typeof _cio !== 'undefined') {
      _cio.track(eventName, payload);
    }
    this.currentPage += 1;
    this._doCalculateProgress();
  }

  public canMoveToPrevious(): boolean {
    return this.currentPage !== 1;
  }

  public canMoveToNext(stepIndex: number): boolean {
    stepIndex -= 1;
    return this.questionnaireForm.at(stepIndex).valid;
  }

  public submitQuestionnaire(): void {
    const formValue = this._doMapFormGroupArrayToSteps();
    const answers: QuestionnaireSubmissionModel = { answers: formValue };
    this.isSubmittingQuestionnaire = true;
    this.submittingQuestionnaireFailed = false;
    this._commonChangeDetector();
    this._activatedRoute.params
      .pipe(
        mergeMap((params) =>
          this.submitQuestionnaireAnswersUseCase
            .execute({ questionnaireName: params.name, data: answers })
            .pipe(take(1)),
        ),
      )
      .subscribe({
        next: (res: { status: string }) => {
          const eventName = `${this.questionnaireName}-questionnaire_submit_click`;
          const payload = answers;
          this._logMixpanelEventUseCase.execute({ eventName, payload });
          if (typeof _cio !== 'undefined') {
            _cio.track(eventName, payload);
          }
          location.replace(`${resolveUserLandingUtility().path}${this._appURLs.PRODUCTS_V2_URL}`);
          // TODO This need to be revisited to have the questionnaire evaluation verdict as optional
          //   https://taager.atlassian.net/browse/MFE-803
          // switch (res.status) {
          //   case 'passed': {
          //     this.questionnaireEvaluationVerdict$.emit('approved');
          //     break;
          //   }
          //   case 'failed': {
          //     this.questionnaireEvaluationVerdict$.emit('addedToWaitingList');
          //     break;
          //   }
          // }
          // this.isSubmittingQuestionnaire = false;
          // this.submittingQuestionnaireFailed = false;
        },
        error: () => {
          this.toaster.error(this._translateService.instant('ERRORS.SOMETHING_WENT_WRONG'));
          this.isSubmittingQuestionnaire = true;
          this.submittingQuestionnaireFailed = true;
          this._commonChangeDetector();
        },
      });
  }

  public doPatchSingleSubFormGroup(formIndex: number, answerId: string): void {
    this._commonPatchFormValue(formIndex, { value: answerId });
  }

  public doPatchMultipleCheckSubFormGroup(
    formIndex: number,
    selectedChoice: string,
    maximumAllowedChoices: number,
  ): void {
    let currentMultipleChoicesSelected = (this.questionnaireForm.at(formIndex).get('value')!
      .value || []) as Array<string>;
    if (currentMultipleChoicesSelected.indexOf(selectedChoice) !== -1) {
      currentMultipleChoicesSelected = currentMultipleChoicesSelected.filter(
        (choiceVal) => choiceVal !== selectedChoice,
      );
    } else if (this.singleSelectAnswersIds.includes(selectedChoice)) {
      currentMultipleChoicesSelected = [];
      currentMultipleChoicesSelected.push(selectedChoice);
    } else {
      if (currentMultipleChoicesSelected.length === maximumAllowedChoices) {
        return;
      }
      currentMultipleChoicesSelected.push(selectedChoice);
      currentMultipleChoicesSelected = currentMultipleChoicesSelected.filter((choice) => {
        return !this.singleSelectAnswersIds.includes(choice);
      });
    }
    this._commonPatchFormValue(formIndex, { value: currentMultipleChoicesSelected });
  }

  public isMultiChoiceFormOptionDisabled(
    formIndex: number,
    choiceValue: string,
    maximumAllowedChoices: number,
  ): boolean {
    const currentMultipleChoicesSelected =
      this.questionnaireForm.at(formIndex).get('value')!.value || [];
    return (
      maximumAllowedChoices === currentMultipleChoicesSelected.length &&
      currentMultipleChoicesSelected.indexOf(choiceValue) === -1
    );
  }

  private _commonChangeDetector(): void {
    this._changeDetectorRef.detectChanges();
  }

  private _doMapFormGroupArrayToSteps(): Array<QuestionAndAnswers> {
    const res: Array<QuestionAndAnswers> = [];
    this.steps.forEach((step: any, index: number) => {
      let groupValue = this.questionnaireForm.at(index).get('value')!.value;
      if (typeof groupValue === 'string') {
        /**
         * We check for this type so that if later down the line we support text and textArea, we
         * can submit as array of one value. Also this applies for current situation for a single
         * choice
         */
        groupValue = [groupValue];
      }
      res.push({
        questionId: step.meta.content.id,
        answerIds: groupValue,
        comment: '',
      });
    });
    return res;
  }

  private _commonPatchFormValue(index: number, formValue: { [control: string]: any }): void {
    this.questionnaireForm.at(index).patchValue({ ...formValue });
  }

  private _initializeQuestionnaireForm(steps: Array<Step>): void {
    steps.forEach((step) => {
      let defaultTypeValue: Array<any> | string = '';
      switch (step.meta.content.type) {
        case 'single':
          defaultTypeValue = '';
          break;
        case 'multiple':
          defaultTypeValue = [];
          break;
      }
      this.questionnaireForm.push(
        new UntypedFormGroup({
          value: new UntypedFormControl(defaultTypeValue, [Validators.required]),
        }),
      );
    });
  }

  private _doCalculateProgress(): void {
    this.currentActiveStep = this.steps[this.currentPage - 1];
    this.singleSelectAnswersIds = this.currentActiveStep.meta.content.answers
      .filter(
        (answer: any) =>
          answer.textArabic.includes('لست متأكداً') || answer.textArabic.includes('أخرى'),
      )
      .map((answer: any) => answer.id);
    this._marshallChoicesBasedOnLanguage(this.currentActiveStep.meta.content.answers);
    this.currentProgress = (this.currentPage / this.steps.length) * 100;
    this._commonChangeDetector();
    this._doInjectStepLabel();
    this._doInjectQuestionBox(this.currentActiveStep.meta);
  }

  private _marshallChoicesBasedOnLanguage(choicesArray: Array<any>): void {
    const currentUserPageLanguage = this._getCurrentLanguage();
    choicesArray.forEach((choice) => {
      choice.languageText = this._returnChoiceTextBasedOnUserLanguage(
        currentUserPageLanguage,
        choice,
      );
    });
  }

  private _returnChoiceTextBasedOnUserLanguage(language: string, choice: any): string {
    switch (language) {
      case 'ar':
        return choice.textArabic;
      case 'en':
        return choice.textEnglish;
      default:
        return choice.textArabic;
    }
  }

  private _doInjectStepLabel(): void {
    this._stepLabelTemplateOutletTemplate.clear();
    if (this._stepLabelComponentRef) {
      this._stepLabelComponentRef.destroy();
    }
    const currentUserPageLanguage = this._getCurrentLanguage();
    const labelsByLanguage = this._returnLabelsByLanguage('stepper', currentUserPageLanguage);
    this._stepLabelComponentRef =
      this._stepLabelTemplateOutletTemplate.createComponent(StepLabelComponent);
    this._stepLabelComponentRef.instance.totalPages = this.steps.length;
    this._stepLabelComponentRef.instance.currentPage = this.currentPage;
    this._stepLabelComponentRef.instance.pageCounterLabel = labelsByLanguage.pageLabel;
    this._stepLabelComponentRef.instance.pageCounterOfLabel = labelsByLanguage.ofLabel;
    this._stepLabelComponentRef.changeDetectorRef.detectChanges();
  }

  private _doInjectQuestionBox(meta: { label: any; content: any }): void {
    this._stepQuestionBoxOutletTemplate.clear();
    if (this._questionBoxComponentRef) {
      this._questionBoxComponentRef.destroy();
    }
    const currentUserPageLanguage = this._getCurrentLanguage();
    const labelsByLanguage = this._returnLabelsByLanguage('questionBox', currentUserPageLanguage);
    this._questionBoxComponentRef =
      this._stepQuestionBoxOutletTemplate.createComponent(QuestionBoxComponent);
    this._questionBoxComponentRef.instance.currentPageDir =
      this._doReturnPageOrientation(currentUserPageLanguage);
    this._questionBoxComponentRef.instance.maxAllowedAnswers = meta.content.maxAllowedAnswers;
    this._questionBoxComponentRef.instance.label = meta.label;
    this._questionBoxComponentRef.instance.question = this._doReturnQuestionContentByLanguage(
      meta.content,
    );
    this._questionBoxComponentRef.instance.iconUrl = meta.content.iconUrl;
    this._questionBoxComponentRef.instance.youCanChooseMoreThanOneOptionLabel =
      labelsByLanguage.youCanChooseMoreThanOneOptionLabel;
    this._questionBoxComponentRef.instance.youCanChooseTwoOptionsLabel =
      labelsByLanguage.youCanChooseTwoOptionsLabel;
    this._questionBoxComponentRef.changeDetectorRef.detectChanges();
  }

  private _doReturnPageOrientation(currentUserPageLanguage: string): 'ltr' | 'rtl' {
    const isRTLLang = ['ar'];
    return isRTLLang.indexOf(currentUserPageLanguage) > -1 ? 'rtl' : 'ltr';
  }

  private _doReturnQuestionContentByLanguage(content: any): string {
    const currentUserPageLanguage = this._getCurrentLanguage();
    switch (currentUserPageLanguage) {
      case 'en':
        return content.textEnglish;
      case 'ar':
        return content.textArabic;
      default:
        return content.textArabic;
    }
  }

  private _returnLabelsByLanguage(
    uiSection: string,
    language: string,
  ): { [attribute: string]: string } {
    const labelsByLanguage: any = {
      stepper: {
        en: {
          ofLabel: 'of',
          pageLabel: 'Question',
        },
        ar: {
          ofLabel: 'من',
          pageLabel: 'السؤال',
        },
      },
      questionBox: {
        en: {
          youCanChooseMoreThanOneOptionLabel: 'You can choose more than one option',
          youCanChooseTwoOptionsLabel: 'You can choose two options',
        },
        ar: {
          youCanChooseMoreThanOneOptionLabel: 'يمكنك اختيار أكثر من خيار',
          youCanChooseTwoOptionsLabel: 'يمكنك اختيار اختيارين',
        },
      },
    };
    return labelsByLanguage[uiSection][language];
  }

  private _getCurrentLanguage(): string {
    return document.documentElement.lang;
  }
}
